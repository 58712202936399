<template>
  <div class="library-main">
    <div class="container library-block">
      <nav class="breadcrumbs-block">
        <ul class="breadcrumbs-list">
          <li class="breadcrumbs-item">
            <router-link to="/">{{ $t('navbar.home') }}</router-link>
          </li>
          <li class="breadcrumbs-item">{{ $t('library') }}</li>
        </ul>
      </nav>
      <h3>{{ $t('library') }}</h3>
      <div class="library-filters">
        <!--        <div class="library-filter-popularity">-->
        <!--          <el-select v-model="popularityValue" :placeholder="$t('libraryPlaceholderByPopularity')">-->
        <!--            <el-option-->
        <!--                v-for="item in popularityOptions"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </div>-->
        <div class="library-filter-chapter">
          <el-select v-model="chapterValue" :placeholder="$t('libraryPlaceholderChapter')" @change="handleChapters">
            <el-option
                v-for="(item, index) in chapters"
                :key="index"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="library-filter-author">
          <el-select v-model="authorValue" :placeholder="$t('libraryPlaceholderAuthor')" @change="handleAuthors">
            <el-option
                v-for="item in authors"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="library-filter-year">
          <el-select v-model="yearValue" :placeholder="$t('libraryPlaceholderYearIssue')" @change="handleYears">
            <el-option
                v-for="(item, index) in years"
                :key="index"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="library-list">
        <router-link :to="'/book/' + book.id" class="library-list-item" v-for="book in filteredItems">
          <el-image
              style="width: 133px; height: 200px"
              :src="bookUrl + book.images[0].image"
              fit="fit"
              @error="handleImageError"
          ></el-image>
          <div class="book-title truncate-title-book">
            {{ formattedAuthors(book.authors) }} — {{ book[`name_${$i18n.locale}`] || book.name_ru }}
          </div>
          <div class="book-desc">
            {{ book[`short_about_${$i18n.locale}`] || book.short_about_ru }}
          </div>
          <button class="library-el-btn" @click.prevent="buyElBook(book)">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278"
                  stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z"
                  stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637"
                  stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551"
                  stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ $t('buyElectronicVersion') }} - {{ book.price | currency }}
          </button>
          <button class="library-paper-btn" @click.prevent="buyInPaperBook(book)">
            {{ $t('buyPaperVersion') }} - {{ book.price | currency }}
          </button>
        </router-link>
      </div>

      <div class="library-pagination">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="50">
        </el-pagination>
      </div>

    </div>

    <!-- Модалка электронной версии (Перенести в одну) -->
    <el-dialog
        :visible.sync="buyElBookLibrary"
        :width="modalWidth"
        center
    >
      <div class="modal-book-title">
        <img src="/images/books-icon.svg" width="52" height="52"/>
        <div>
          <p class="modal-book-subtitle">
            {{ $t('purchaseElectronicVersion') }}
          </p>
        </div>
      </div>

      <div class="modal-book-card">
        <img v-if="currentBook.images" :src="bookUrl + currentBook.images[0].image" width="133" height="200"/>
        <div class="price-tag">
          {{ currentBook.price | currency }}
        </div>
        <div class="book-card-desc">
          {{ formattedAuthors(currentBook.authors) }} — {{ currentBook[`name_${$i18n.locale}`] || currentBook.name_ru }}
        </div>
      </div>

      <div>
        <button @click="closeModal" class="btn-cancel">
          {{ $t('profile.notes.cancel-btn') }}
        </button>
        <button @click="purchase" class="btn-buy">
          Оплата
        </button>
      </div>
    </el-dialog>

    <!-- Модалка печатной версии (Перенести в одну) -->
    <el-dialog
        :visible.sync="buyPaperBookLibrary"
        :width="modalWidth"
        center
    >
      <div class="modal-book-title">
        <img src="/images/books-icon.svg" width="52" height="52"/>
        <div>
          <p class="modal-book-subtitle">
            {{ $t('purchasePaperVersion') }}
          </p>
        </div>
      </div>

      <div class="modal-book-card">
        <el-image
            v-if="currentBook.images"
            style="width: 133px; height: 200px"
            :src="bookUrl + currentBook.images[0].image"
            fit="fit"
            @error="handleImageError"
        />
        <div class="price-tag">
          {{ currentBook.price | currency }}
        </div>
        <div class="book-card-desc">
          {{ formattedAuthors(currentBook.authors) }} — {{ currentBook[`name_${$i18n.locale}`] || currentBook.name_ru }}
        </div>
      </div>

      <div>
        <button @click="closeModal" class="btn-cancel">
          {{ $t('profile.notes.cancel-btn') }}
        </button>
        <button class="btn-buy" @click="showDeliveryForm">
          Оплата
        </button>
      </div>
    </el-dialog>

    <!-- Форма для скачивания -->
    <el-dialog
        :visible.sync="downloadSelectedBook"
        :width="modalWidth"
        center
    >
      <div class="modal-book-title">
        <div>
          <p class="modal-book-subtitle">
            Нажмите чтобы скачать файл
          </p>
        </div>
      </div>
      <div class="download-book-img">
        <img :src="selectedBook && selectedBook.images ? bookUrl + selectedBook.images[0].image : null" width="224" height="335"/>
      </div>

      <button @click="download" class="download-book-link">
        <div class="d-flex flex-column" style="max-width: 90%">
          <span class="text-truncate">{{ selectedBook ? selectedBook[`name_${$i18n.locale}`] || selectedBook.name_ru : null }}</span>
          <span>{{ selectedBook ? selectedBook.file_size : '0 KB' }}</span>
        </div>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
              stroke="white" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M14.6953 20.9468L19.9986 26.2501L25.3019 20.9468" stroke="white" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20 13.75V26.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </el-dialog>

    <!-- Форма доставки -->
    <el-dialog
        :visible.sync="sendingSelectedPaperBook"
        :width="modalWidth"
        center
    >
      <div class="modal-book-title">
        <div>
          <p class="modal-book-subtitle">
            {{ $t('purchasePaperVersion') }}
          </p>
          <p class="modal-book-text">
            {{ formattedAuthors(currentBook.authors) }} — {{ currentBook[`name_${$i18n.locale}`] }}
          </p>
        </div>
      </div>
      <div class="modal-paper-book-card">
        <el-image
            v-if="currentBook.images"
            style="width: 77px; height: 116px"
            :src="bookUrl + currentBook.images[0].image"
            fit="fit"
            @error="handleImageError"
        />
        <div class="d-flex flex-column align-items-center">
          <div class="book-title">
            {{ currentBook[`short_about_${$i18n.locale}`] }}
          </div>
          <div class="book-price">
            {{ currentBook.price | currency }}
          </div>
        </div>
      </div>

      <div class="sending-list-data">
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Почтовый индекс *
          </div>
          <el-input v-model="deliveryData.postal_code"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Область *
          </div>
          <el-select
              :placeholder="$t('references.region-placeholder')"
              :loading="regions.loading"
              v-model="deliveryData.region_id"
              filterable
          >
            <el-option
                v-for="item in regions.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Район *
          </div>
          <el-select
              :placeholder="$t('references.region-placeholder')"
              :loading="cities.loading"
              v-model="deliveryData.city_id"
              :disabled="cities.disabled"
              filterable
          >
            <el-option
                v-for="item in cities.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Населенный пункт *
          </div>
          <el-select
              :placeholder="$t('references.district-placeholder')"
              :loading="localities.loading"
              :disabled="localities.disabled"
              v-model="deliveryData.locality_id"
              filterable
          >
            <el-option
                v-for="item in localities.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Адрес *
          </div>
          <el-input v-model="deliveryData.address"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            ФИО*<br>
            (на русском)
          </div>
          <el-input v-model="deliveryData.full_name"></el-input>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Контактный телефон *
          </div>
          <el-input v-model="deliveryData.phone"></el-input>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            E-mail *
          </div>
          <el-input v-model="deliveryData.email"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="btns-paper-book">
          <button @click="buyElBook = false" class="btn-cancel">
            Отменить
          </button>
          <button class="btn-buy" @click="purchase">
            Подтвердить покупку
          </button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import md5 from "@/plugins/md5";

export default {
  name: "Library",
  data() {
    return {
      chapterValue: '',
      yearValue: '',
      authorValue: '',
      popularityValue: '',
      popularityOptions: [
        {
          value: 'Option1',
          label: 'Option1'
        }, {
          value: 'Option2',
          label: 'Option2'
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Option4',
          label: 'Option4'
        }, {
          value: 'Option5',
          label: 'Option5'
        }
      ],
      buyElBookLibrary: false,
      buyPaperBookLibrary: false,
      buyPaperBook: false,
      downloadSelectedBook: false,
      selectedBook: null,
      downloadLink: null,
      sendingSelectedPaperBook: false,
      modalWidth: "440px",
      modalWidthPaperBook: "36%",
      deliveryData: {
        postal_code: null,
        region_id: null,
        city_id: null,
        locality_id: null,
        address: null,
        full_name: null,
        phone: null,
        email: null,
      },
      booksInfo: [],
      bookUrl: window.API_ROOT + '/storage/',
      currentBook: {
        authors: [
          {
            name_ru: '',
            name_kz: '',
            name_en: '',
          }
        ]
      },
      currentType: null,
      authors: [],
      years: [],
      chapters: [],
      displayedItems: [],
      regions: {
        loading: false,
        data: [],
      },
      cities: {
        loading: false,
        disabled: true,
        data: [],
      },
      localities: {
        loading: false,
        disabled: true,
        data: [],
      },
    }
  },
  methods: {
    handleChapters() {
      this.displayedItems = this.booksInfo.filter(item => item[`chapter_${this.$i18n.locale}`] == this.chapterValue);
      this.authorValue = '';
      this.yearValue = '';
    },
    handleYears() {
      this.displayedItems = this.booksInfo.filter(item => item.publication_year == this.yearValue);
      this.authorValue = '';
      this.chapterValue = '';
    },
    handleAuthors() {
      this.displayedItems = this.booksInfo.filter(item => item.authors.some(author => author.id === this.authorValue));
      this.yearValue = '';
      this.chapterValue = '';
    },
    formattedAuthors(authors) {
      return authors
          .map(author => author[`name_${this.$i18n.locale}`])
          .join(", ");
    },
    handleImageError() {
      console.log('Error');
    },
    buyElBook(book) {
      this.currentBook = book;
      this.buyElBookLibrary = true;
      this.currentType = 'ONLINE';
    },
    buyInPaperBook(book) {
      this.currentBook = book;
      this.buyPaperBookLibrary = true;
      this.currentType = 'OFFLINE';
    },
    closeModal() {
      this.buyElBookLibrary = false;
      this.buyPaperBookLibrary = false;
    },
    getRegions() {
      this.regions.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/regions`)
          .then((res) => {
            if (res.body.data) {
              this.regions.data = res.body.data;
            }
            this.regions.loading = false;
          })
          .catch((e) => {
            this.regions.loading = false;
          });
    },
    getCities(regionId) {
      this.cities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/districts/${regionId}`)
          .then((res) => {
            if (res.body.data) {
              this.cities.data = res.body.data;
            }
            this.cities.loading = false;
          })
          .catch((e) => {
            this.cities.loading = false;
          });
    },
    getLocalities(cityId) {
      this.localities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/localities/${cityId}`)
          .then((res) => {
            if (res.body.data) {
              this.localities.data = res.body.data;
            }
            this.localities.loading = false;
          })
          .catch((e) => {
            this.localities.loading = false;
          });
    },
    showDeliveryForm() {
      this.closeModal();
      this.sendingSelectedPaperBook = true;
    },
    async purchase() {
      const login = 'book1';
      const password = 'wQSCZEwg3qjiY8R10bm9';
      const lastInvoice = await this.$http.get(`${window.API_ROOT}/api/library/delivery/last-invoice`);
      const invId = lastInvoice.body.invoice;
      const outSum = parseInt(this.currentBook.price).toString();
      const signature = md5(`${login}:${outSum}:${invId}:${password}`);

      localStorage.setItem('lastBoughtBook', JSON.stringify({
        ...this.deliveryData,
        bookId: this.currentBook.id,
        type: this.currentType,
      }));

      window.open(`https://auth.robokassa.kz/Merchant/Index.aspx?MerchantLogin=${login}&OutSum=${outSum}&InvoiceID=${invId}&SignatureValue=${signature}`);
    },
    download() {
      window.open(this.downloadLink);
      this.downloadSelectedBook = false;
    },
  },
  computed: {
    filteredItems() {
      return this.displayedItems;
    },
  },
  watch: {
    'deliveryData.region_id'(id) {
      this.getCities(id);
      this.cities.disabled = false;
      this.deliveryData.city_id = null;
      this.deliveryData.locality_id = null;
    },
    'deliveryData.city_id'(id) {
      if (id) {
        this.getLocalities(id);
      }
      this.localities.disabled = false;
      this.deliveryData.locality_id = null;
    }
  },
  mounted() {
    const { query } = this.$route;

    const uuid = localStorage.getItem('bookUuid');
    if (query.type === 'ONLINE' && uuid) {
      const lastBoughtData = JSON.parse(localStorage.getItem('lastBoughtBook'));
      this.$http.get(window.API_ROOT + `/api/library/${lastBoughtData.bookId}`).then(res => {
        this.selectedBook = res.body.data;
        this.downloadSelectedBook = true;
        this.downloadLink = `${window.API_ROOT}/api/library/download/${uuid}`;
        localStorage.removeItem('bookUuid');
        localStorage.removeItem('lastBoughtBook');
      });
    }

    if (window.innerWidth < 450) {
      this.modalWidth = "100%";
    }

    this.$http.get(window.API_ROOT + `/api/library`)
        .then(res => {
          this.booksInfo = res.body.data;
          this.displayedItems = res.body.data;

          // получаю список уникальных авторов
          const authorsMap = new Map();
          this.booksInfo.forEach(slide => {
            slide.authors.forEach(author => {
              const key = author.id;
              const name = author[`name_${this.$i18n.locale}`];
              if (!authorsMap.has(key)) {
                authorsMap.set(key, {name, id: key});
              }
            });
          });
          this.authors = Array.from(authorsMap.values());

          // получаю список уникальных годов выпуска
          this.years = [...new Set(this.booksInfo.map(item => item.publication_year))];

          // получаю список уникальных разделов
          this.chapters = [...new Set(this.booksInfo.map(item => item[`chapter_${this.$i18n.locale}`]))];
        });

    this.getRegions();
  }
}
</script>

<style>
.library-block {
  padding-top: 75px;
}

.library-main {
  position: relative;
  padding-bottom: 100px;
}

.library-main::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 500px;
  background: linear-gradient(to right, #32436D, #1A84B2, #02C7FA);
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  z-index: -1;
}

.library-main::after {
  position: absolute;
  content: "";
  width: 300px;
  height: 420px;
  right: -60px;
  bottom: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/library-back.png");
}

.library-block .el-input__inner {
  background-color: transparent;
}

.library-filters {
  display: flex;
  gap: 25px;
}

.library-filter-popularity {
  position: relative;
}

.library-filter-popularity::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/ArrowsDownUp.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-popularity input {
  padding-left: 40px;
}

.library-filter-chapter {
  position: relative;
}

.library-filter-chapter::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/view-list.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-chapter input {
  padding-left: 40px;
}

.library-filter-author {
  position: relative;
}

.library-filter-author::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/woman.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-author input {
  padding-left: 40px;
}

.library-filter-year {
  position: relative;
}

.library-filter-year::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/table-file.svg");
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.library-filter-year input {
  padding-left: 40px;
}

.library-list-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 32%;
  background-color: #FFF;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2.74px 105.6px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 30px;
  transition: 0.5s;
  text-decoration: none;
}

.library-list-item:hover {
  background-color: rgba(235, 245, 255, 1);
  cursor: pointer;
  text-decoration: none;
}

.library-list-item:hover > .library-el-btn:not([disabled]) {
  border: 1.33px solid rgba(250, 132, 67, 1);
  background-color: rgba(250, 132, 67, 1);
  color: #FFF;
  padding: 12px 5px;
}

.library-list-item:hover > .library-el-btn:not([disabled]) svg {
  display: inline;
}

.book-title {
  color: rgba(50, 67, 109, 1);
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: -1.5px;
  font-family: "Montserrat", sans-serif;
}

.truncate-title-book {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.book-desc {
  color: rgba(148, 151, 161, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
}

.library-el-btn {
  border: 1.33px solid rgba(16, 144, 203, 1);
  color: rgba(16, 144, 203, 1);
  border-radius: 13px;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  background-color: #FFF;
  transition: 0.5s;
  display: flex;
  justify-content: space-around;
}

.library-el-btn svg {
  display: none;
}

.library-paper-btn {
  border: 1.33px solid rgba(16, 144, 203, 1);
  color: #FFF;
  border-radius: 13px;
  padding: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  background-color: rgba(16, 144, 203, 1);
  transition: transform 0.5s ease;
}

.library-paper-btn:not([disabled]):hover {
  transform: scale(1.05);
}

.library-el-btn[disabled],
.library-paper-btn[disabled] {
  opacity: 0.7;
}

.library-pagination {
  text-align: center;
  margin-top: 30px;
}

.library-pagination .el-pagination.is-background .btn-next {
  border-radius: 50%;
  border: 1px solid rgba(241, 241, 241, 1);
  background-color: #FFF;
  width: 43px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  font-size: 18px;
}

.library-pagination .el-pagination.is-background .btn-prev {
  border-radius: 50%;
  border: 1px solid rgba(241, 241, 241, 1);
  background-color: #FFF;
  width: 43px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  font-size: 18px;
}

.library-pagination .el-pagination.is-background .el-pager li {
  border-radius: 50%;
  border: 1px solid rgba(241, 241, 241, 1);
  background-color: #FFF;
  width: 43px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  font-size: 18px;
}

.library-pagination .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(16, 144, 203, 1);
}

.library-filter-popularity input::placeholder,
.library-filter-chapter input::placeholder,
.library-filter-author input::placeholder,
.library-filter-year input::placeholder {
  color: rgba(242, 242, 242, 1);
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.modal-book-title {
  display: flex;
  gap: 16px;
}

.modal-book-title div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.modal-book-title p {
  margin: 0;
  padding: 0;
  word-break: normal;
}

.modal-book-card {
  margin: 30px auto;
  width: 300px;
  border: 2px solid rgba(16, 144, 203, 1);
  border-radius: 12px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.modal-book-card::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 16px;
  right: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/checkbox-tick.svg");
}

.price-tag {
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  font-family: "Inter", sans-serif;
}

.book-card-desc {
  color: rgba(50, 67, 109, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  word-break: normal;
}

.btn-cancel {
  width: 100%;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  padding: 10px 18px;
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
}

.btn-buy {
  width: 100%;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  padding: 10px 18px;
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  background: rgba(16, 144, 203, 1);
  margin-top: 10px;
  display: inline-block;
  text-align: center;
}

.btn-buy:hover {
  color: #fff;
  text-decoration: none;
}

.modal-book-subtitle {
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  font-family: "Montserrat", sans-serif;
}

.modal-book-text {
  color: rgba(50, 67, 109, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
}

.download-book-img {
  margin-top: 40px;
  margin-bottom: 23px;
  text-align: center;
}

.download-book-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(16, 144, 203, 1);
  color: #FFF;
  text-decoration: none !important;
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  gap: 8px;
  border: none;
  text-align: left;
}

.download-book-link:hover {
  color: #FFF;
}

.modal-paper-book-card {
  display: flex;
  gap: 32px;
  padding: 15px;
  border: 1.5px solid rgba(16, 144, 203, 1);
  border-radius: 7px;
  max-width: 250px;
  margin: 20px 0;
}

.modal-paper-book-card .book-title {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  font-family: "Inter", sans-serif;
  color: rgba(71, 84, 103, 1);
  word-break: normal;
  letter-spacing: 0px;
}

.modal-paper-book-card .book-price {
  font-weight: 600;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: rgba(50, 67, 109, 1);
  margin-top: 18px;
}

.sending-column-data {
  min-width: 200px;
}

.sending-list-data {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.demarcation-line {
  width: 100%;
  height: 1px;
  background: rgba(228, 231, 236, 1);
}

.btns-paper-book {
  display: flex;
  gap: 15px;
}

.btns-paper-book .btn-buy {
  margin-top: 0;
}

.library-filter-author .el-input__inner,
.library-filter-year .el-input__inner,
.library-filter-chapter .el-input__inner {
  color: #FFF;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
}
</style>
